@import "core/index.scss";
.page-home {
	.section {
		&.home-showcase-1 {
			padding-bottom: 0;
	
			@include media {
				padding-top: 1rem;
			}
		}
	
		&.home-showcase-2 {
			padding-bottom: 0;
	
			@include media {
				padding-top: 4rem;
			}
		}
	
		&.home-showcase-3 {
			padding: 5rem 0 0;
	
			@include media {
				padding-top: 5rem;
			}
		}
	}
}