@import "core/index.scss";
.section.home-mainslider {
	
	.mainslider-nav {
		background-color: $color-pink-bg;
		color: $color-pink-darker;
		padding: 2rem;
		
		@include media {
			color: $color-text;
			white-space: nowrap;
			overflow-x: scroll;;
			border-top: .1rem solid $color-bg;
			padding: 1.5rem 0 1rem;
			background-color: transparent;
		}
	}

		.nav-wrap {
			display: flex;
			justify-content: center;

			@include media {
				display: block;
				max-width: 100%;
				white-space: nowrap;
				padding: 0 2rem;

				@include media {
					padding: 0 0 0 2rem;
				}
				// overflow-x: scroll;
			}
		}

			.nav-item {
				@include font-semibold;
				border-radius: $radius-general;
				padding: 0 2rem;
				line-height: 4rem;
				position: relative;
				white-space: nowrap;
				transition: background-color $transition-hover ease, color $transition-hover ease;

				&.placeholder {
					width: 18rem;
					height: 4rem;
					display: inline-block;
					background-color: $color-primary;

					@include media {
						width: 7rem;
						height: 1.9rem;
					}
				}
				
				@include mediaMin {
					&:not(.placeholder):not(.active) {
						&:hover {
							background-color: darken($color-primary, $darken-ratio);
							color: $color-white;
						}
					}

					&.active {
						color: $color-white;
						background-color: $color-primary;

						&:after {
							transform: translate3d(0, 0, 0);
							opacity: 1;
						}
					}

					&:after {
						@include pseudo;
						width: 0;
						height: 0;
						border: .7rem solid transparent;
						border-top-color: $color-primary;
						position: absolute;
						left: 1.8rem;
						top: 100%;
						opacity: 0;
						transform: translate3d(0, -50%, 0);
						transition: opacity $transition-hover ease, transform $transition-hover ease;
					}

					+ .nav-item {
						margin-left: 2rem;
					}
				}

				@include media {
					font-size: 1.2rem;
					line-height: 2rem;
					padding: .5rem 1rem;
					border-radius: $radius-small;

					+ .nav-item {
						margin-left: .5rem;
					}

					&.active {
						color: $color-white;
						background-color: $color-primary;
					}

					&:last-child {
						margin-right: 2rem;
					}

					&.placeholder {
						width: 10rem;
					}
				}
			}

	.mainslider-sliderwrap {
		@include media {
			padding: 1rem 2rem 3rem;
			margin-top: -1rem;
			overflow: hidden;

			.sliderwrap-slider {
				overflow: visible;

				.swiper-wrapper {
					align-items: normal;
				}
			}
		}
	}

		.sliderwrap-slider {
			&.placeholder {
				height: 54rem;

			}

			@include media {
				width: calc(100% + 4rem);
				margin: 0 -2rem;

				&.placeholder {
					height: 29.2rem;
				}
			}
		}
			
			.swiper-slide {
				@include media {
					padding: 0 2rem;
					height: auto;
					display: flex;
				}

				&.swiper-slide-active {
					.slide-textcontent {
						opacity: 1;
						transform: translate3d(0, 0, 0);
					}
				}
			}

			.mainslider-slide {
				position: relative;
				height: 54rem;
				width: 100%;
				display: block;

				@include media {
					height: auto;
					height: auto;
					display: flex;
					flex-direction: column;
					overflow: hidden;
					border-radius: $radius-big;
					box-shadow: $shadow-big;

					&.image-only {
						.slide-image {
							height: 100%;
						}
					}
				}

			}

				.slide-image {
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-color: $color-bg;

					@include media {
						position: relative;
						height: 19rem;
					}
				}

					.image-video {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0);
						min-width: 100%;
						min-height: 100%;
					}

				.slide-innerwrap {
					
					@include mediaMin {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						height: 100%;
						// padding: 0 2rem;
					}

					@include media {
						max-width: 100%;
						width: 100%;
						flex: 1 0 0;
						display: flex;
					}
				}

				.slide-textcontent {
					position: relative;
					z-index: 2;
					background-color: $color-pink;
					color: $color-white;
					padding: 4rem 5rem;
					width: 100%;
					
					@include mediaMin {
						border-radius: $radius-general;
						box-shadow: $shadow-big;
						max-width: 49.5rem;
						opacity: 0;
						transform: translate3d(0, -.8rem, 0);
						transition: opacity $transition-anim ease 700ms, transform $transition-anim ease 700ms;
					}

					@include media {
						padding: 2rem 2rem 2.2rem;
						border-bottom-left-radius: $radius-big;
						border-bottom-right-radius: $radius-big;
						min-height: 10.2rem;
					}
				}

					.textcontent-label {
						margin-bottom: 2.2rem;

						@include media {
							margin-bottom: 1.2rem;
						}
					}

					.textcontent-title {
						@include font-bold;
						display: block;
						font-size: 4rem;
						line-height: 4.8rem;
						letter-spacing: .025em;

						@include media {
							@include font-semibold;
							font-size: 1.5rem;
							line-height: 2rem;
						}
					}

					.textcontent-cta {
						margin-top: 2.8rem;

						@include media {
							margin-top: 1.4rem;
						}
					}

		.sliderwrap-thumbs {
			width: 69rem;
			max-width: 100%;
			padding: 0 3rem;
			margin: 1.5rem auto 1.5rem;
			overflow: hidden;
			position: relative;
			z-index: 2;

			@include mediaMin {
				@include maskSlider(3rem);
			}

			.swiper-button-prev, .swiper-button-next { 
				background: none;
				width: 2.5rem;
				height: 2.5rem;
				box-shadow: none;

				@include mediaMin {
					&:not(.swiper-button-disabled) {
						&:hover {
							background-color: transparent;
							color: $color-text-blue;
						}
					}
				}
			}

			&.static {
				display: flex;
				justify-content: center;

				.thumbs-thumb {
					width: calc(100% / 7);
				}
			}
		}
		
			.thumbs-thumb {
				padding: 0 .6rem;
				height: 5.8rem;
			}

				.thumb-btn {
					border-radius: $radius-general;
					padding: .3rem;
					border: .1rem solid transparent;
					display: block;
					width: 100%;
					height: 100%;
					cursor: pointer;
					transition: border-color $transition-hover ease;

					@include mediaMin {
						&:not(.active) {
							&:hover {
								border-color: rgba($color-gray, .5);
							}
						}
					}

					&.active {
						border-color: $color-gray;
					}
				}

					.thumb-imagewrap {
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: center;
						background-color: $color-bg;
					}
}